<template>
  <v-app
    class="content"
    v-resize="onResize"
    ref="apppp"
    v-on:redraw="onRedraw"
  >
    <!--    Left vertical menu -->
    <v-navigation-drawer
        v-if="isLoggedIn"
        permanent
        app
        clipped
        v-model="drawer"
        :mini-variant="mini"
        mini-variant-width="80px"
        expand-on-hover
    >
      <v-list
          nav
          dense
          class="py-0 my-0"
      >
        <v-list-item v-if="isLoggedIn" link @click.prevent="logOut">
          <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          <v-list-item-title>LogOut</v-list-item-title>
        </v-list-item>

        <v-list-item link v-if="isAdmin" icon to="/config">
          <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
          <v-list-item-title >Einstellungen</v-list-item-title>
        </v-list-item>

      </v-list>

      <v-divider></v-divider>

      <v-list
          nav
          dense
          v-for="item in this.menuStore.leftMenu"
          :key="item.link"
          class="py-0 my-0"
      >
        <v-divider v-if="item.icon == 'divider'"></v-divider>
        <v-list-item v-if="(item.icon!=null)&&(hasRole(item.role))"
          @click="clickLeftMenu(item)"
        >
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{item.tooltip}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!--    Top area with: Title, Up- anf Download Buttons, AppMenu and Top-Menu -->
    <v-app-bar
        app
        class="header"
        clipped-left
        v-if="isLoggedIn"
        ref="toolbar"
    >
      <span class="mr-2 text-uppercase">{{ currentUser.username }}</span>

      <v-spacer></v-spacer>

      <v-toolbar-title>BKEST-{{menuStore.title}}</v-toolbar-title>

      <v-spacer></v-spacer>

      <!--    Up-, Download, Print, Excel, PDF,  -->
      <v-tooltip
          v-if="(menuStore.loader != null) && (menuStore.loader.up != null) && (isAdmin)"
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <span
              v-bind="attrs"
              v-on="on"
          >
            <v-file-input accept=".xls, .xlsm, .csv, .txt, .zip, .json"
                          v-model="choosenFile"
                          prepend-icon="mdi-file-upload"
                          hide-input
                          @change="uploadFile"
                          class="flex-row-reverse"
                          :key="fileSelectKey"
                          :loading="uploading"

            >
            </v-file-input>
          </span>
        </template>
        <span>{{menuStore.loader.upTip}}</span>
      </v-tooltip>

      <v-tooltip
          v-if="(menuStore.loader != null)&&(menuStore.loader.down != null)"
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <span
              v-bind="attrs"
              v-on="on"
          >
          <v-btn class="mr-2"
                 icon
                 :loading="downloading"
                 @click="downloadFile">
            <v-icon>mdi-file-download</v-icon>
          </v-btn>
          </span>
        </template>
        <span>{{menuStore.loader.downTip}}</span>
      </v-tooltip>

      <v-tooltip
          v-if="(this.menuStore.loader != null)&&(this.menuStore.loader.print != null)"
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <span
              v-bind="attrs"
              v-on="on"
          >
          <v-btn class="mr-2"
                 icon
                 download
                 @click="print">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          </span>
        </template>
        <span>{{this.menuStore.loader.printTip}}</span>
      </v-tooltip>

      <v-tooltip
          v-if="(this.menuStore.loader != null)&&(this.menuStore.loader.excel != null)"
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <span
              v-bind="attrs"
              v-on="on"
          >
          <v-btn class="mr-2"
                 icon
                 download
                 @click="excel">
            <v-icon>mdi-file-excel-box</v-icon>
          </v-btn>
          </span>
        </template>
        <span>{{this.menuStore.loader.excelTip}}</span>
      </v-tooltip>

      <v-tooltip
          v-if="(this.menuStore.loader != null)&&(this.menuStore.loader.pdf != null)"
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <span
              v-bind="attrs"
              v-on="on"
          >
          <v-btn class="mr-2"
                 icon
                 download
                 @click="pdf">
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
          </span>
        </template>
        <span>{{this.menuStore.loader.pdfTip}}</span>
      </v-tooltip>

      <!--    App menu -->
      <span
          v-for="item in menuStore.getAppMenu"
          :key="item.link"
      >
        <v-tooltip
            bottom
            v-if="hasRole(item.role)"
        >
          <template v-slot:activator="{ on, attrs }">
            <span
                v-bind="attrs"
                v-on="on"
            >
              <v-btn class="mr-2"
                     text
                     @click="clickAppMenu(item)">
                {{item.text}}
                <v-icon right>{{item.icon}}</v-icon>
              </v-btn>
            </span>
          </template>
          <span>{{item.tooltip}}</span>
        </v-tooltip>
       </span>

      <!--    Top Menu menu as Tabs -->
      <template v-slot:extension>
        <v-tabs
            class="d-flex justify-start py-0"
            align-with-title
            v-if="menuStore.showTopMenu"
            v-model="selTopIdx"
            :key="doRedraw"
        >
          <v-tab
              v-for="entry in topMenu"
              :key="entry.link"
              @click="clickTopMenu(entry)"
          >
            {{ entry.title }}
          </v-tab>
        </v-tabs>
        <v-spacer
            class="d-flex justify-start py-0"
            align-with-title
            v-if="!menuStore.showTopMenu"
        ></v-spacer>
        <v-spacer></v-spacer>
        <v-select
            class="d-flex justify-end py-0"
            style="max-width: 150px"
            v-if="menuStore.showSJ"
            :items="schuljahre"
            :prepend-icon="isReadOnly"
            v-model="currSJ"
            v-on:change="updatesj"
        ></v-select>
        <span
            class="d-flex justify-start py-0"
            align-with-title
            v-if="!menuStore.showTopMenu"
        >SJ{{schuljahr}}</span>
      </template>
    </v-app-bar>

    <v-main ref="main"
            v-on:redraw="onRedraw"
            v-model="currSJ"
    >
      <router-view  :key="$route.fullPath" />
    </v-main>
    <v-alert
        ref="alert"
        style="position:fixed; center:true; width:60%; z-index:100; left: 50%; transform: translate(-50%, 10%); margin: 0 auto;"
        :style="{top:contentPosY+'px'}"
        :value="alertStore.visible"
        :type="alertStore.type"
        dark
        :icon="alertStore.icon"
        transition="slide-y-transition"
    >
      {{alertStore.text}}
    </v-alert>
    <v-dialog
        ref="working"
        style="position:fixed; center:true; width:60%; z-index:100; left: 50%; transform: translate(-50%, 10%); margin: 0 auto;"
        :style="{top:contentPosY+'px'}"
        :value="loadStore.visible"
        :type="loadStore.type"
        :icon="loadStore.icon"
        transition="slide-y-transition"
        width="70%"
        center
        light
    >
      <v-card
      >
        <v-card-text
            class="h5"
        >
          {{loadStore.text}}
          <v-progress-linear
              color="primary"
              class="mb-0"
              height="10"
              v-model="loadStore.cur"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-footer
        app
        dense
        ref="footer"
    >
      <v-container
        fluid
        class="footer"
        >
        <div class="stBar">
          <v-progress-linear
              v-if="stale == false"
              height="3px"
              :buffer-value="progvalue"
          >
          </v-progress-linear>
          <v-progress-linear
              v-if="stale != false"
              height="3px"
              buffer-value="0"
          >
          </v-progress-linear>
        </div>
        <v-divider vertical ></v-divider>
        <div class="stZeit myLeft">{{time}}</div>
        <div class="stLast myLeft"></div>
        <v-divider vertical ></v-divider>
        <div class="stText myLeft"><div v-if="stale == false">{{lastMessage}}</div></div>
        <v-divider vertical ></v-divider>
        <div class="stUser myRight">
          <div class="px-0" v-if="menuStore.isEPlan">
            Zuletzt aktiv: {{lastUser}} @ {{lastUserChange}}
          </div>
          <div class="px-0" v-if="menuStore.isVert">
            {{lastUpdate}}
          </div>
        </div>
          <v-divider vertical ></v-divider>
        <div class="stCopy myRight">
          <div class="px-1">{{version.substr(0,8)}}</div><v-icon class="px-1">mdi-copyright</v-icon><div  class="px-1">J. Hergeth</div>
        </div>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
// import AuthService from './services/auth.service';
import {mapGetters} from 'vuex';
import { mapStores } from 'pinia';
import {useHeightsStore } from '@/stores/heights';
import {useSchuljahreStore } from '@/stores/schuljahre';
import {useMenuStore } from '@/stores/menu';
import {useAlertStore } from '@/stores/alert';
import {useLoadStore } from '@/stores/load';
import TransferService from "@/services/transfer.service";
import * as Const from './config';
import { consoleInfo } from 'vuetify/lib/util/console';
import { useEventBus } from "@vueuse/core";
import { useConfigStore } from "@/stores/config";

import MoodleLogo from './assets/moodlelogo.svg';

export default {
  name: 'App',

  data: () => ({
    version: '',
    time: '',
    lastUpdate: '0:00',
    lastMessage:'',
    toDo: 0,
    done: 0,
    stale: false,
    lastUser: '',
    lastUserChange: '',
    lastEPlanChange: 0,
    schuljahr: '',

    progvalue:0,
    selTopIdx: null,
    selectedTab: null,
    drawer: true,
    group: null,
    dataSize: 0,
    mini:true,
    tabHeight: "100%",
    on:'',
    attrs:'',
    contentPosY:0,
    downloading:false,
    uploading: false,
    schulName: "",
    getConfig: null,
    isReadOnly: "",
    currSJ: null,
    schuljahre: [],
    timeout: null,
    fileSelectKey: 0,
    choosenFile: null,
    redrawTopMenu: 0,
    topMenu: [],
    doRedraw:0,
    interval: null,

    bus: null,
  }),

  components: {
    MoodleLogo,
  },

   mounted(){
//    AuthService.create(this);
     consoleInfo("APP: Init ");
     let that = this;

     that.schuljahre = that.schuljahreStore.getSchuljahre();
     that.currSJ = that.schuljahreStore.selectedSJ;
     consoleInfo("APP: Init set SJ=" + that.currSJ + " sizeof(schuljahre)=" + that.schuljahre.length);
     that.bus = useEventBus('eplan')
     this.schuljahreStore.$subscribe(async (mutation, state) => {
       that.currSJ = state.selectedSJ;
       that.schuljahre = that.schuljahreStore.getSchuljahre();
       consoleInfo("APP: Subscription set SJ=" + that.currSJ + " sizeof(schuljahre)=" + that.schuljahre.length);
     })

     that.updatesj();
//     that.clickAppMenu({})


     this.fileSelectKey++;
     this.saveHeights();

     this.interval = setInterval(function(){ that.getStatus(that);}, 500);
  },

  computed: {
    ...mapGetters( 'auth', ['currentUser', 'hasRole', 'isLoggedIn', 'isAdmin', 'isTeacher', 'isAccManager', 'isEplan', 'isEplanManager' ]),
    ...mapGetters( 'domain', ['getCfgItem' ]),
    ...mapStores(useMenuStore, useHeightsStore, useAlertStore, useSchuljahreStore, useLoadStore, useConfigStore)
  },

  methods: {
    getStatus: function (that) {
      if(that.isLoggedIn){
        TransferService.get(Const.STATUS_API).then((data) => {
          let currentDate = new Date();
          let time = currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds();
          that.time = time;
          if (data != undefined) {
            if (data.alertText != undefined) {
              that.alertStore.show(data.alertText, data.alertType, 4000, data.alertIcon);
            }
            that.version = data.version;
            that.time = data.timeNow;
            that.lastUpdate = "Untis: " + (data.lastUpdate === "---" ? "keine" : data.lastUpdate);
            that.lastMessage = data.message;
            that.toDo = data.toDo;
            that.done = data.done;
            that.stale = data.stale;
            that.currSJ = that.$cookies.get("SJ");
            that.schuljahr = data.schuljahr;
            that.lastUser = data.lastUser;
            that.lastUserChange = data.lastUserChange;
            if(that.lastEPlanChange != data.lastEPlanChange){
              that.lastEPlanChange = data.lastEPlanChange;
              console.log(`eplanchanged: ${that.lastEPlanChange}`);
              that.bus.emit('eplanchanged')
            }


            if(that.currSJ == null){  // no cookie set????
              that.currSJ = that.schuljahreStore.selectedSJ;
            }

            const readOnly = (this.schuljahreStore.getFlags() & 1) && !this.isEplanManager;
            if(readOnly){
              that.isReadOnly = "mdi-pencil-off-outline";
            }
            else{
              that.isReadOnly = "";
            }


            if (!data.stale) {
              clearInterval(that.interval);
              if(data.toDo > 0 && that.toDo != that.done){
                that.progvalue = Math.round(data.done / data.toDo * 100);
                that.loadStore.update(that.progvalue, that.lastMessage);
                console.info("Update load to " + that.progvalue);
                that.interval = setInterval(function(){ that.getStatus(that);}, 200);
              } else {
                that.progvalue = 0;
                that.toDo = 0;
                that.loadStore.finish();
                that.downloading = false;
                that.uploading = false;
                that.interval = setInterval(function(){ that.getStatus(that);}, 500);
              }
            }
            if (that.stale !== false && that.toDo != 0 ) {
              that.toDo = 0;
              that.done = 0;
            }
//          that.saveHeights();
          }
//        setTimeout(that.getStatus(that), 5000);
        });
      }
//      that.eplanStore.poll();
    },

    updatesj: function(event){
      if(this.currSJ != null){
        console.info("APP: Set new Schuljahr=" + this.currSJ)
        if(this.isLoggedIn){
          this.schuljahreStore.setSJ(this.currSJ)
        }
        let that = this;

        that.topMenu = this.menuStore.getCurrTopMenu(this.currSJ);
        that.selTopIdx = this.menuStore.getCurrTopMenuIdx(this.currSJ);
        that.redrawTopMenu +=1;
        that.doRedraw += 1;
      }
    },

    onRedraw(){
      this.doRedraw += 1;
    },

    onResize(){
      this.saveHeights();
    },

    saveHeights(){
      let height = 0;
      const toolbar = this.$refs.toolbar;
      const footer = this.$refs.footer;
      const main = this.$refs.main;
      if(toolbar){
        height = `${toolbar.$el.offsetHeight}px`;
        document.documentElement.style.setProperty('--headerHeight', height);
        if(footer && main){
          const heights = this.heightsStore;
          const height = window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight;
          heights.toolbarHeight = toolbar.$el.clientHeight;
          heights.footerHeight = footer.$el.clientHeight;
          heights.mainHeight = height - heights.toolbarHeight - heights.footerHeight;
          heights.winHeight = height;
          this.contentPosY = heights.toolbarHeight;
          this.doRedraw += 1;
          this.topMenu = this.menuStore.getCurrTopMenu(this.currSJ);
          consoleInfo("APP: App-Resize heights: winheight=" + heights.winHeight
              + " toolbarheight=" + heights.toolbarHeight
              + " footerheight=" + heights.footerHeight
              + " userheight=" + heights.getUserHeight
              + " mainheight=" + heights.mainHeight
          );
        }
      }
    },

    logOut() {
      this.menuStore.showTopMenu = false;

      this.schuljahreStore.init().then( sj => {
        let currSJ = sj;
        let schuljahre = this.schuljahreStore.sj;
        consoleInfo("APP-Logout: Init set SJ=" + currSJ + " sizeof(schuljahre)=" + schuljahre.length);

        this.menuStore.init(schuljahre, currSJ).then(() => {
          this.$store.dispatch('auth/logout');
          this.pushRoute('/login');
        })
      });
    },

    clickAppMenu(ent){
      this.selTopIdx = 0;
      this.menuStore.clickAppMenu({item: ent, router: this});
      this.topMenu = this.menuStore.topMenu;
      this.mini = true;
//      consoleInfo("APP: AppMenu clicked: top=" + this.menuStore.selAppMenu.tooltip);
    },

    clickLeftMenu(ent){
      if(ent.topMenu != 5 || this.selTopIdx == undefined){
        this.selTopIdx = 0;
      }
      this.mini = true;

      let that = this;
      this.schuljahreStore.init();
      this.menuStore.clickLeftMenu({item: ent, router: this, topSel: this.selTopIdx});
      this.topMenu = this.menuStore.topMenu;
//      consoleInfo("APP: LeftMenu clicked: top=" + this.menuStore.selAppMenu.tooltip+ " left=" + this.menuStore.selLeftMenu.tooltip);
    },

    clickTopMenu(ent){
      this.mini = true;
      this.menuStore.clickTopMenu({item: ent, router: this});
      this.topMenu = this.menuStore.topMenu;
//      consoleInfo("APP: TopMenu clicked: top=" + this.menuStore.selAppMenu.tooltip+ " left=" + this.menuStore.selLeftMenu.title + " top="+ ent + " idx:" + this.selTopIdx);
    },

    // handleFileUpload(){
    //   this.choosenFile = this.$refs.file.files[0];
    // },
    uploadFile() {
      this.fileSelectKey++;
      this.uploading = true;
      this.menuStore.callUpload( this.choosenFile);
    },

    downloadFile() {
      this.downloading = true;
      this.menuStore.callDownload();
    },
    print(){
      this.menuStore.callPrint();
    },
    excel(){
      this.menuStore.callExcel();
    },
    pdf(){
      this.menuStore.callPdf();
    },

    pushRoute(params){
      // get comparable fullPaths
      let from  = this.$route.fullPath
      let to    = this.$router.resolve(params).route.fullPath

      if(from === to) {
        // handle any error due the redundant navigation here
        // or handle any other param modification and route afterwards
        return
      }

      this.menuStore.loader = { };
      // route as expected
      this.$router.push(params);

      this.saveHeights();
    }
  },

};
</script>
<style lang="css">

html { 
  height: 100%;
  margin:0;
  overflow-y: hidden;
  z-index: -1;
}

.content {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.content .header {
  flex: 0 1 auto;
}

.content .center {
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.content .footer {
  display: grid;
  grid-template-columns: 160px 1px auto 200px 170px;
  grid-template-rows: auto;
  grid-template-areas:
    "a a a a a"
    "b1 b2 b3 b4 b5"
  ;
  grid-gap: 0.1rem;
  max-width: 100%;
  height:30px;
  margin:0;
  padding: 0;
}

.myLeft {
  display: flex;
  justify-content: left;
  padding-bottom: 2px;
}
.myRight {
  display: flex;
  justify-content: right;
  padding-bottom: 2px;
}
.stZeit { grid-area: b1;}
.stLast { grid-area: b2;}
.stText { grid-area: b3;}
.stUser { grid-area: b4;}
.stCopy { grid-area: b5;}
.stBar { grid-area: a; }

.v-alert {
  position:absolute;
  z-index:100;
  left: 50%;
  transform: translate(-50%, 10%);
  margin: 0 auto;
}
</style>
